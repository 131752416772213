<template>
  <BaseModal
    :showing="visible"
    @close="handleClose"
    :showClose="visible"
    :backgroundClose="true"
    size="max-w-6xl"
  >
    <div class="space-y-6">
      <div class="flex justify-between">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Detail Barang
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Berdasarkan data barang yang telah dibuat
          </p>
        </div>
        <div>
          <img src="@/assets/images/logo.png" alt="" class="w-32" />
        </div>
      </div>
      <div class="border-t border-gray-200 pt-6" v-if="visible">
        <slot name="content" />
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6 sm:pb-6">
          <slot
            v-if="withAttributes"
            name="attribute"
            :product="getProduct"
            :is-product="getProduct.data.attributes.is_product"
          >
            <div v-if="withActiveToggle" class="sm:col-span-6">
              <dt class="text-sm font-bold text-gray-700">Status</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <toggle-button
                  v-model="getProduct.data.attributes.is_active"
                  :labels="{
                    checked: 'Aktif',
                    unchecked: 'Non Aktif',
                  }"
                  :width="getProduct.data.attributes.is_active ? 60 : 80"
                  v-on:change="handleChangeActive"
                />
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Kode Barang</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getProduct.data.attributes.code }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Nama Barang</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getProduct.data.attributes.name }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Kategori Barang</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getProduct.data.attributes.product_category_name }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Kode Supplier</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getProduct.data.attributes.supplier_code || '-' }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Nama Supplier</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getProduct.data.attributes.supplier_name || '-' }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Harga Supplier</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  (getProduct.data.attributes.supplier_price ?? 0) | toCurrency
                }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">P/N</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  !!getProduct.data.attributes.is_point ? 'Point' : 'Non Point'
                }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">PV</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getProduct.data.attributes.point }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">BV</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getProduct.data.attributes.bonus | toCurrency }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Lembaga Ijin</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getProduct.data.attributes.license_institution }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Status Izin</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <span
                  v-if="getProduct.data.attributes.license_status"
                  class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                >
                  Lengkap
                </span>
                <span
                  v-else
                  class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                >
                  Sedang Proses
                </span>
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">
                Mulai Izin - Akhir Izin
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getProduct.data.attributes.license_start }}
                <span class="px-1 font-light text-gray-300">s.d</span>
                {{ getProduct.data.attributes.license_end }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Dibuat</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getProduct.data.attributes.createdAt | formatDate }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">
                Terakhir Diperbarui
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getProduct.data.attributes.updatedAt | formatDate }}
              </dd>
            </div>
          </slot>
        </dl>
        <div
          v-if="withProductPrices && getProduct.data.attributes.is_product"
          class="flex flex-col"
        >
          <div class="my-2 -flex sm:-mx-6 lg:-mx-8">
            <div
              class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
            >
              <div
                class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
              >
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Area
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Harga Stockist
                      </th>
                      <th
                        scope="col"
                        v-if="!(viewAs == 'kantor-pelayanan')"
                        class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Harga Mitra Usaha
                      </th>
                      <th
                        v-if="!(viewAs == 'kantor-pelayanan')"
                        scope="col"
                        class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Harga Konsumen
                      </th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white">
                    <tr>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{
                          this.getProductPrice('Wilayah Jawa', 'Stockist')
                            ?.attributes.area ?? 0
                        }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          (this.getProductPrice('Wilayah Jawa', 'Stockist')
                            ?.attributes.price ?? 0) | toCurrency
                        }}
                      </td>
                      <td
                        v-if="!(viewAs == 'kantor-pelayanan')"
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          (this.getProductPrice('Wilayah Jawa', 'Mitra Usaha')
                            ?.attributes.price ?? 0) | toCurrency
                        }}
                      </td>
                      <td
                        v-if="!(viewAs == 'kantor-pelayanan')"
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          (this.getProductPrice('Wilayah Jawa', 'Konsumen')
                            ?.attributes.price ?? 0) | toCurrency
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{
                          this.getProductPrice('Wilayah I', 'Stockist')
                            ?.attributes.area ?? 0
                        }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          (this.getProductPrice('Wilayah I', 'Stockist')
                            ?.attributes.price ?? 0) | toCurrency
                        }}
                      </td>
                      <td
                        v-if="!(viewAs == 'kantor-pelayanan')"
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          (this.getProductPrice('Wilayah I', 'Mitra Usaha')
                            ?.attributes.price ?? 0) | toCurrency
                        }}
                      </td>
                      <td
                        v-if="!(viewAs == 'kantor-pelayanan')"
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          (this.getProductPrice('Wilayah I', 'Konsumen')
                            ?.attributes.price ?? 0) | toCurrency
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{
                          this.getProductPrice('Wilayah II', 'Stockist')
                            ?.attributes.area ?? 0
                        }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          (this.getProductPrice('Wilayah II', 'Stockist')
                            ?.attributes.price ?? 0) | toCurrency
                        }}
                      </td>
                      <td
                        v-if="!(viewAs == 'kantor-pelayanan')"
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          (this.getProductPrice('Wilayah II', 'Mitra Usaha')
                            ?.attributes.price ?? 0) | toCurrency
                        }}
                      </td>
                      <td
                        v-if="!(viewAs == 'kantor-pelayanan')"
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          (this.getProductPrice('Wilayah II', 'Konsumen')
                            ?.attributes.price ?? 0) | toCurrency
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{
                          this.getProductPrice('Wilayah III', 'Stockist')
                            ?.attributes.area ?? 0
                        }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          (this.getProductPrice('Wilayah III', 'Stockist')
                            ?.attributes.price ?? 0) | toCurrency
                        }}
                      </td>
                      <td
                        v-if="!(viewAs == 'kantor-pelayanan')"
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          (this.getProductPrice('Wilayah III', 'Mitra Usaha')
                            ?.attributes.price ?? 0) | toCurrency
                        }}
                      </td>
                      <td
                        v-if="!(viewAs == 'kantor-pelayanan')"
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          (this.getProductPrice('Wilayah III', 'Konsumen')
                            ?.attributes.price ?? 0) | toCurrency
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{
                          this.getProductPrice('Wilayah IV', 'Stockist')
                            ?.attributes.area ?? 0
                        }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          (this.getProductPrice('Wilayah IV', 'Stockist')
                            ?.attributes.price ?? 0) | toCurrency
                        }}
                      </td>
                      <td
                        v-if="!(viewAs == 'kantor-pelayanan')"
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          (this.getProductPrice('Wilayah IV', 'Mitra Usaha')
                            ?.attributes.price ?? 0) | toCurrency
                        }}
                      </td>
                      <td
                        v-if="!(viewAs == 'kantor-pelayanan')"
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{
                          (this.getProductPrice('Wilayah IV', 'Konsumen')
                            ?.attributes.price ?? 0) | toCurrency
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <dl
          v-if="
            withAdditionalProductPrices && getProduct.data.attributes.is_product
          "
          class="mt-4 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 sm:pb-6"
        >
          <div
            v-if="!(viewAs == 'kantor-pelayanan')"
            class="flex items-center space-x-3 rounded-lg border border-gray-200 bg-white px-4 py-3 shadow-sm"
          >
            <div class="min-w-0 flex-1">
              <dt class="text-sm font-bold text-gray-700">Harga Karyawan</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  (this.getProductPrice('Wilayah Jawa', 'Karyawan')?.attributes
                    .price ?? 0) | toCurrency
                }}
              </dd>
            </div>
          </div>
          <div
            v-if="!(viewAs == 'kantor-pelayanan')"
            class="flex items-center space-x-3 rounded-lg border border-gray-200 bg-white px-4 py-3 shadow-sm"
          >
            <div class="min-w-0 flex-1">
              <dt class="text-sm font-bold text-gray-700">Harga Khusus</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  (this.getProductPrice('Wilayah Jawa', 'Khusus')?.attributes
                    .price ?? 0) | toCurrency
                }}
              </dd>
            </div>
          </div>
          <div
            class="flex items-center space-x-3 rounded-lg border border-gray-200 bg-white px-4 py-3 shadow-sm"
          >
            <div class="min-w-0 flex-1">
              <dt class="text-sm font-bold text-gray-700">Free</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  (this.getProductPrice('Wilayah Jawa', 'Free')?.attributes
                    .price ?? 0) | toCurrency
                }}
              </dd>
            </div>
          </div>
        </dl>
      </div>
      <div class="flex justify-end gap-x-2">
        <button
          @click="handleClose"
          type="button"
          class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
        >
          Tutup
        </button>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseModal from '../base/BaseModal.vue';
import { ToggleButton } from 'vue-js-toggle-button';

export default {
  name: 'ProductDetailModal',
  components: { BaseModal, ToggleButton },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    withActiveToggle: {
      type: Boolean,
      default: true,
    },
    withAdditionalProductPrices: {
      type: Boolean,
      default: true,
    },
    withAttributes: {
      type: Boolean,
      default: true,
    },
    withProductPrices: {
      type: Boolean,
      default: true,
    },
    viewAs: {
      type: String,
      default: 'pusat',
    },
  },
  emits: ['close', 'updated'],
  computed: {
    ...mapGetters({
      getProduct: 'products/getProduct',
    }),
  },
  methods: {
    ...mapActions({
      updateProduct: 'products/updateProduct',
    }),
    getProductPrice(areaName, buyerType) {
      const productPrice = this.getProduct.included.find((include) => {
        return (
          include.attributes.area === areaName &&
          include.attributes['buyer-type'] === buyerType
        );
      });

      return productPrice;
    },
    handleClose() {
      this.$emit('close');
    },
    handleChangeActive() {
      const payload = {
        data: {
          type: 'products',
          id: this.getProduct.data.id,
          attributes: {
            is_active: this.getProduct.data.attributes.is_active,
          },
        },
      };
      this.updateProduct(payload).then(() => {
        this.$emit('updated');
      });
    },
  },
};
</script>
