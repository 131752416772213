<template>
  <div>
    <base-modal :showing="visible" @close="$emit('close')" @opened="onOpened">
      <div v-if="user.data" class="space-y-6">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-4">
            <div>
              <h3 class="text-lg font-bold leading-6 text-gray-900">
                Detail User
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Berdasarkan data user yang telah dibuat
              </p>
            </div>
          </div>
        </div>
        <div class="space-y-4 border-t border-gray-200 pt-6">
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Nama User</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ user.data.attributes.name ?? '-' }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Role</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ user.data.attributes.current_role ?? '-' }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Email</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ user.data.attributes.email ?? '-' }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Office</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ getSingleIncluded(user, user.data.relationships.office.data.id).attributes.code }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Status</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <toggle-button
                  :labels="{
                    checked: 'Suspend',
                    unchecked: 'Aktif',
                  }"
                  :width="user.data.attributes.is_suspended ? 80 : 58"
                  v-model="user.data.attributes.is_suspended"
                  v-on:change="onSuspendChange"
                />
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Dibuat</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ user.data.attributes.createdAt | formatDate }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">
                Terakhir Diperbarui
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ user.data.attributes.updatedAt | formatDate }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">
                Terakhir Login
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ user.data.attributes.last_active | formatDate }}
              </dd>
            </div>
          </dl>
        </div>
        <div class="flex justify-end">
          <base-button color="transparent" @click="onEditPassword"
            >Ubah Kata Sandi</base-button
          >
        </div>
      </div>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import { ToggleButton } from 'vue-js-toggle-button';

export default {
  mixins: [requestMixin],
  components: { BaseModal, ToggleButton },
  props: {
    visible: Boolean,
    reload: Boolean,
    userId: String,
  },
  emits: ['close', 'edit-password'],
  data() {
    return {
      loading: false,
      user: {
        data: null,
      },
    };
  },
  methods: {
    async onOpened() {
      if (this.reload) {
        this.loading = true;

        this.breadcrumbs = [];

        await this.loadUsers();

        this.loading = false;
      }
    },
    async onSuspendChange() {
      this.loading = true;

      await this.request(`/api/v1/users/${this.userId}`, {
        method: 'patch',
        data: {
          data: {
            type: 'users',
            id: this.userId,
            attributes: {
              is_suspended: this.user.data.attributes.is_suspended,
            },
          },
        },
      });
      await this.loadUsers();

      this.loading = false;
    },
    onEditPassword() {
      this.$emit('edit-password', {
        link: this.getSingleIncluded(this.user, this.user.data.relationships.office.data.id).attributes.office_type === 'member'
      });
    },
    async loadUsers() {
      const [res, error] = await this.request(`/api/v1/users/${this.userId}`, {
        params: {
          'include': 'office',
          'fields[users]': 'office,name,current_role,email,is_suspended,createdAt,updatedAt,last_active',
          'fields[offices]': 'code,office_type'
        }
      });

      if (!error) {
        this.user = res;
      }

      return [res, error];
    },
  },
};
</script>
