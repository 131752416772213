<template>
  <div>
    <base-modal
      :showing="visible"
      size="max-w-xl"
      @close="$emit('close')"
      @opened="onOpened"
    >
      <form class="space-y-4" @submit.prevent="onSave">
        <div class="text-center">
          <h3
            class="text-lg font-medium leading-6 text-gray-900"
            id="modal-title"
          >
            Ubah Kata Sandi
          </h3>
        </div>
        <base-input
          label="Kata Sandi Baru"
          placeholder="Kata Sandi Baru"
          inset
          type="password"
          v-model="form.password"
        />
        <base-input
          label="Konfirmasi Kata Sandi Baru"
          placeholder="Konfirmasi Kata Sandi Baru"
          inset
          type="password"
          v-model="form.password_confirmation"
        />
        <div class="grid grid-cols-2">
          <base-button fullwidth>Simpan</base-button>
          <base-button
            fullwidth
            color="transparent"
            type="button"
            @click="$emit('close')"
            >Tutup</base-button
          >
        </div>
      </form>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: { BaseModal },
  props: {
    visible: Boolean,
    userId: String,
  },
  emits: ['close', 'edit-password'],
  data() {
    return {
      loading: false,
      form: {
        password: null,
        password_confirmation: null,
      },
    };
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    onOpened() {
      this.form.password = null;
      this.form.password_confirmation = null;
    },
    async onSave() {
      this.loading = true;

      const [, error] = await this.request(`/api/v1/users/${this.userId}`, {
        method: 'patch',
        data: {
          data: {
            type: 'users',
            id: this.userId,
            attributes: {
              password: this.form.password,
              password_confirmation: this.form.password_confirmation,
            },
          },
        },
      });

      if (error) {
        this.createAlert({
          data: error.message,
          status: 'error',
        });
      } else {
        this.$emit('close');
      }

      this.loading = false;
    },
  },
};
</script>
